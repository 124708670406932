import React from "react";
import Classes from "./index.module.scss";
import { Tooltip } from "antd";
import { ReactComponent as CopyIcon } from "../../assets/images/icons/copy.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { formatLargeNumber } from "../../helpers/common";
function TabListing({ data, titles, listingTitle }) {
   
    
  return (
    <div className={Classes.reinvestHistory}>
      <div>
        <h2 className={Classes.listingTitle}>{listingTitle}</h2>
        <div className="overflow-auto">
          <table>
          <tr>
              {titles &&
                titles?.length > 0 &&
                titles?.map((title) => {
                  return <th>{title}</th>;
                })}
            </tr>
              {
                data&&data?.length>0&&data?.map((row,index)=>{
                  return (
                    <React.Fragment key={row.key || index}>
                    {row}
                  </React.Fragment>
                  )
                })
              }
            
          </table>
        </div>
      </div>
    </div>
  );
}

export default TabListing;
