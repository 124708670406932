import api from "../index";
const createStake = (data) => {
  try {
    return api.post("/user/stake", data);
  } catch (err) {
    console.log(err, "error=>");
  }
};
const updateStake = (id, txHash) => {
  try {
    return api.post(`/stake/update/${id}`, { txHash });
  } catch (err) {
    console.log(err, "error=>");
  }
};
const completeStack = (data) => {
  try {
    return api.post(`/transaction/complete`, data);
  } catch (err) {
    console.log(err, "error=>");
  }
};
const getStakeAmountByUser = () => {
  try {
    return api.get(`/stake/user/totalAmount`);
  } catch (err) {
    console.log(err, "error=>");
  }
};
const getStakesByUser = (type) => {
  try {
    return api.get(`/stake?type=${type}`);
  } catch (err) {
    console.log(err, "error=>");
  }
};
const getDaoMembersStakes = () => {
  try {
    return api.get(`/stake/dao-stake-percentage`);
  } catch (err) {
    console.log(err, "error=>");
  }
};

export {
  createStake,
  updateStake,
  completeStack,
  getStakeAmountByUser,
  getStakesByUser,
  getDaoMembersStakes
};
