import React, { useState, useEffect } from "react";
import Classes from "./index.module.scss";
import { Tooltip } from "antd";
import { ReactComponent as CopyIcon } from "../../assets/images/icons/copy.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { formatLargeNumber } from "../../helpers/common";
import { getDaoMembersStakes } from "../../api/services/stake";
import { calculatePercentage } from "../../helpers/common";
import TabListing from "../Tablisting";
function DaoPercentagesListing() {
    const [daoPercentages, setDaoPercentages] = useState([]);
    const formatData = (data, setData,totalDaoStakeAmount) => {
      const formatedData = data.map((item) => {
        const txHash = item.transactionId?.txHash?.slice(0, 5) +
        "..." +
        item.transactionId?.txHash?.slice(-5)
        return  <tr key={txHash}>
        <td>
          <p className={Classes.field}>{item?.name||'-'}</p>
        </td>
        <td>
          <p className={Classes.field}>{`${ formatLargeNumber(item.totalStakeAmount||0)} USDC`}</p>
        </td>
        <td>
          <p className={Classes.field}>{  item?.walletAddress?.slice(0, 5) +
            "..." +
            item?.walletAddress?.slice(-5)}</p>
        </td>
        <td>
          <div className="d-flex align-items-center justify-content-center gap-2">
            <p className={Classes.field}>{`${calculatePercentage(item.totalStakeAmount, totalDaoStakeAmount)}%`}</p>
          </div>
        </td>
      </tr>
      });
      setData(formatedData);
    };
    const fetchAllStakesByUser = async () => {
        try {
          const res = await getDaoMembersStakes();
          if (res?.data?.data?.users?.length > 0) {
            formatData(res.data.data.users, setDaoPercentages,res.data.data.totalStakeAmount);
          }
      
        } catch (err) {}
      };
      const fetchData = () => {
        fetchAllStakesByUser();
      };
      useEffect(() => {
        fetchData();
      }, []);
      const titles = ["Name", "Amount", "Wallet Address", "Percentage"];
  return (
    <div className={Classes.daoPercentages}>
    <TabListing data={daoPercentages} titles={titles} listingTitle="Dao Ownership Percentages" />
    </div>
  );
}

export default DaoPercentagesListing;
